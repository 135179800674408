<template>
  <div>
    <TabsCards :tabs="tabsData" v-on:change="selectTab"></TabsCards>
    <v-row class="row-cards">
      <ChartCard
        :sizes="{
          default: 12
        }"
        :chart="chart"
        :customOptions="customOptions"
        visibleCard
      >
      </ChartCard>
    </v-row>
    <slot>
      <v-row v-if="displayChart" class="row-cards">
        <Table
          :loading="table.loading"
          :items="table.data"
          :fields="getHeader"
          visibleCard
        />
      </v-row>
    </slot>
  </div>
</template>

<script>
import TabsCards from "@/views/partials/content/cards/TabsCards";
import Table from "@/views/partials/content/cards/Table";
import ChartCard from "@/views/partials/content/cards/Chart";
import ApiService from "@/common/api.service";
import { mapGetters } from "vuex";
import { getQueryFilters } from "@/common/functions";
import { headers } from "@/common/config/dashboard/conversion";

import { dashboardStats } from "@/api/v1/getters";

export default {
  name: "ConversionLayout",

  props: {
    displayChart: {
      type: Boolean,
      default: true
    },
    tabsData: {
      type: Array
    },
    APIslug: {
      type: String
    },
    adminFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    inputFilters: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  components: {
    ChartCard,
    Table,
    TabsCards
  },

  data() {
    return {
      timeout: 25000,
      APIdata: [],
      table: {
        data: [],
        loading: false
      },
      exportCSV: {
        data: [],
        loading: true
      },
      chart: {
        title: "",
        legends: "",
        type: "bar",
        data: {
          labels: [],
          datasets: []
        },
        loading: true
      },
      chartCountsCancelToken: null,
      DetailsCancelToken: null,
      customOptions: {}
    };
  },

  mounted() {
    this.showChartCounts();
    this.fetchStatistics();
    if (this.$route.query.tab) {
      this.selectTab(this.$route.query.tab);
      this.$router.replace({ query: null });
    }
  },

  computed: {
    ...mapGetters(["filters", "header"]),

    getHeader() {
      return headers;
    }
  },

  methods: {
    showDatas(datas, graph) {
      if (graph.renderFigure) {
        if (graph.value) {
          graph.primaryStat = graph.renderFigure(datas[graph.value]);
        } else {
          graph.primaryStat = graph.renderFigure(datas);
        }
      } else {
        // TODO uniformiser avec le module i18n de nuxt
        graph.primaryStat = new Intl.NumberFormat(navigator.language).format(
          datas[graph.value]
        );
      }
      graph.loading = false;
    },

    showChartCounts() {
      if (this.filters.dateRange && this.filters.dateRange.length > 1) {
        for (const key in this.tabsData) {
          this.tabsData[key].loading = true;
        }

        if (this.chartCountsCancelToken != null) {
          this.chartCountsCancelToken.cancel(
            ApiService.getCancelErrorMessage()
          );
        }

        this.chartCountsCancelToken = ApiService.getCancelToken();

        new Promise(() => {
          dashboardStats(
            this.APIslug,
            "global",
            { ...this.getQueryFilters() },
            this.axios,
            {
              cancelToken: this.chartCountsCancelToken.token,
              timeout: this.timeout
            }
          )
            .then(data => {
              for (const key in this.tabsData) {
                this.showDatas(data, this.tabsData[key]);
                this.tabsData[key].loading = false;
              }
            })
            .catch(error => {
              if (error.message !== "Operation canceled due to new request.") {
                for (const key in this.tabsData) {
                  this.tabsData[key].loading = false;
                }
              }
            });
        });
      }
    },

    getQueryFilters() {
      let filters = {
        start_date: this.filters.dateRange[0].substring(0, 10),
        end_date:
          new Date(this.filters.dateRange[1]) > new Date()
            ? new Date().getTime() / 1000
            : this.filters.dateRange[1].substring(0, 10)
      };

      let header = this.adminFilters.concat(this.inputFilters);

      return getQueryFilters(filters, header);
    },

    getFilterStr(filterSlug) {
      return this.filters[filterSlug] ? this.filters[filterSlug].join(",") : "";
    },

    selectTab(key) {
      for (const i in this.tabsData) {
        if (key !== i) {
          this.tabsData[i].selected = false;
        }
      }
      this.tabsData[key].selected = true;
      this.normalizeChartData(this.APIdata);
    },

    getSelectedTab() {
      return this.tabsData.findIndex(tab => {
        return tab.selected === true;
      });
    },

    normalizeLabels(labels) {
      return labels.map(label => {
        return this.formalizedDateLabel(label);
      });
    },

    formalizedDateLabel(label) {
      return this.$d(new Date(label), "short");
    },

    normalizeChartData(data) {
      let selectedTab = this.tabsData[this.getSelectedTab()];

      const labels = this.normalizeLabels(Object.keys(data));
      const values = Object.values(data);

      let datasets = [];
      for (let chartDataToDisplay2 of selectedTab.chartDataToDisplay) {
        const figures = values.map(dayData => {
          if (chartDataToDisplay2.renderData) {
            return chartDataToDisplay2.renderData(dayData) || 0;
          }
          return dayData[chartDataToDisplay2.value] || 0;
        });

        const dataset = {
          fill: true,
          data: figures,
          type: chartDataToDisplay2?.type,
          label: chartDataToDisplay2.label,
          backgroundColor: chartDataToDisplay2.color,
          borderColor: chartDataToDisplay2.borderColor
            ? chartDataToDisplay2.borderColor
            : chartDataToDisplay2.color
        };

        datasets.push(dataset);
      }

      this.chart.title = selectedTab.chartTitle
        ? selectedTab.chartTitle
        : selectedTab.title;
      this.customOptions = selectedTab.customOptions;
      this.chart = {
        ...this.chart,
        ...{
          data: { datasets, labels },
          type: selectedTab.chartOptions?.chartType
            ? selectedTab.chartOptions?.chartType
            : "bar",
          stacked: selectedTab.chartOptions?.stacked,
          isTimeChart: selectedTab.isTimeChart
        }
      };
    },

    reset() {
      this.chart.loading = true;
      this.table.loading = true;

      this.APIdata = [];
      this.table.data = [];
      this.exportCSV.data = [];

      this.$root.$emit("exportData", this.exportCSV.data);
    },

    fetchStatistics() {
      if (this.filters.dateRange && this.filters.dateRange.length > 1) {
        let selectedTab = this.tabsData[this.getSelectedTab()];

        this.reset();

        if (this.detailsCancelToken != null) {
          this.detailsCancelToken.cancel(ApiService.getCancelErrorMessage());
        }

        this.detailsCancelToken = ApiService.getCancelToken();
        this.table.loading = true;

        dashboardStats(
          this.APIslug,
          "daily",
          { ...this.getQueryFilters() },
          this.axios,
          {
            cancelToken: this.chartCountsCancelToken.token,
            timeout: this.timeout
          }
        )
          .then(data => {
            delete data["debugQuery"];
            this.APIdata = data;

            // Thats ugly
            let filteredData = Object.keys(data).reduce((obj, key) => {
              obj[key] = { ...data[key] };
              return obj;
            }, {});

            this.normalizeChartData(filteredData);

            this.chart.loading = false;
          })
          .catch(error => {
            console.error("---dashboard daily error---");
            console.error(error);
            if (error.message !== "Operation canceled due to new request.") {
              this.chart.loading = false;
            }
          });

        dashboardStats(
          this.APIslug,
          "table",
          { ...this.getQueryFilters() },
          this.axios,
          {
            cancelToken: this.chartCountsCancelToken.token,
            timeout: this.timeout
          }
        )
          .then(data => {
            delete data["debugQuery"];

            if (selectedTab.normalizeDataTable) {
              this.table.data = selectedTab.normalizeDataTable(data);
            }

            if (typeof selectedTab.normalizeExportData == "function") {
              this.exportCSV.data = selectedTab.normalizeExportData(data);
            }

            this.$root.$emit("exportData", this.exportCSV.data);

            this.table.loading = false;
          })
          .catch(error => {
            console.error("---dashboard table error---");
            console.error(error);
            this.table.loading = false;
          });
      }
    }
  },

  watch: {
    filters(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.showChartCounts();
        this.fetchStatistics();
      }
    }
  }
};
</script>
