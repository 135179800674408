import { get } from "@/api/getApi1.0";
import { post } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getDataFilters = (axios, field, filters) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.FILTERS.STATS.path,
      ressources: [
        {
          name: field,
          value: ENTITY_NAME_IN_URI.FILTERS.STATS.value
        }
      ]
    },
    filters,
    axios
  );

export const getTermsFilters = (axios, field, params = {}, data = {}) =>
  post(
    {
      path: ENTITY_NAME_IN_URI_V2.FILTERS.path,
      ressources: [
        {
          name: field
        }
      ]
    },
    params,
    data,
    axios
  );
