<template>
  <div>
    <div v-if="isRemainingWarning" class="kt-container mt-5">
      <router-link
        :to="{ name: 'profile' }"
        class="w-100"
        title="Manage account"
      >
        <b-alert show variant="warning" class="d-flex">{{
          $t("INFORMATIONS.EXPIRED_PASSWORD_DAYS", {
            days: getRemainingDays,
            date: calculateDate
          })
        }}</b-alert>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AlertExpirePassword",

  computed: {
    ...mapGetters(["currentUser"]),

    passwordExpirationDetails() {
      return this.currentUser && this.currentUser.passwordExpirationDetails;
    },

    getRemainingDays() {
      return (
        this.passwordExpirationDetails &&
        this.passwordExpirationDetails.remainingDays
      );
    },

    getDaysBeforeWarning() {
      return (
        this.passwordExpirationDetails &&
        this.passwordExpirationDetails.daysBeforeWarning
      );
    },

    getExpirationDate() {
      return this.passwordExpirationDetails.expirationDate;
    },

    calculateDate() {
      return this.$d(new Date(this.getExpirationDate), "short");
    },

    isRemainingWarning() {
      return this.getDaysBeforeWarning === 0;
    }
  }
};
</script>
