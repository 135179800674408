<template>
  <span>
    <span
      id="btn-rf"
      v-if="filterSelectedCounter > 0"
      @click="clearFilters"
      class="text-muted ml-3"
      style="cursor:pointer"
      v-b-tooltip.hover
      title="Clear filters"
    >
      <SVGIcoFilter></SVGIcoFilter>
    </span>
    <span
      v-if="filterSelectedCounter > 0"
      style="font-weight: 500; transform: translateY(-10px); height: 14px; width: 14px;"
      class="kt-badge kt-badge--danger"
      >{{ filterSelectedCounter }}</span
    >
  </span>
</template>

<script>
import { mapGetters } from "vuex";
import SVGIcoFilter from "@/views/partials/widgets/SVGIcoFilter";
import { clearFilters } from "@/common/filters";

export default {
  components: {
    SVGIcoFilter
  },

  computed: {
    ...mapGetters(["filters"]),

    filterSelectedCounter() {
      let countFilterSelected = 0;
      const keys = Object.keys(this.filters);
      keys.map(key => {
        if (key !== "dateRange" && this.filters[key].length > 0) {
          countFilterSelected++;
        }
      });

      return countFilterSelected;
    }
  },

  methods: {
    clearFilters() {
      clearFilters(this.filters, this.$store);
      this.$root.$emit("clear");
    }
  }
};
</script>
