<template>
  <b-table
    stacked="sm"
    class="cutom-table"
    v-on:row-clicked="handleClick"
    v-if="items.length > 0"
    :fields="filteredFields"
    hover
    :items="items"
  >
    <template v-slot:cell(date)="row">
      {{ $d(new Date(row.item.date), "short") }}
    </template>

    <template v-slot:cell(name)="row">
      <span v-bind:class="rowClass(row.item)" style="cursor: pointer">
        {{ row.item.name }}
      </span>
    </template>

    <template #custom-foot>
      <b-tr>
        <b-td v-for="(column, index) in fields" :key="column.key">
          <b class="float-right" v-if="index === 0">Total</b>
          <b v-else>{{ calculateTotal[column.key] }}</b>
        </b-td>
      </b-tr>
    </template>
  </b-table>
</template>

<style>
.cutom-table thead th {
  color: #b5b5c3;
  text-transform: uppercase;
  font-weight: 600;
}

.cutom-table tr td:first-child {
  font-weight: 600;
}
</style>

<script>
import { mapGetters } from "vuex";
import { SET_FILTERS } from "@/store/stats.module";

export default {
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rowClickable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    filteredFields() {
      return this.fields.map(field => {
        if (field.role === "admin") {
          if (this.isAdmin()) {
            return field;
          } else {
            return false;
          }
        }
        return field;
      });
    },
    calculateTotal() {
      const except = ["conversionrate", "fillrate", "rpm", "rpm:plays"];

      const data = {};
      this.fields.forEach((field, index) => {
        if (index !== 0 && !except.includes(field.key)) {
          data[field.key] = this.items.reduce(
            (total, item) => total + item[field.key],
            0
          );

          data[field.key] = this.isFloat(data[field.key])
            ? data[field.key].toFixed(2)
            : data[field.key];
        }
      });

      data["conversionrate"] =
        this.conversionRate(data["plays:paid"], data["impressions"]) + "%";
      data["fillrate"] =
        this.fillRate(data["plays:paid"], data["inventories"]) + "%";
      data["rpm"] = this.rpm(data["impressions"], data["earnings"]) + "€";
      data["rpm:plays"] =
        this.rpmPlays(data["plays:organic"], data["earnings"]) + "€";
      return data;
    }
  },
  methods: {
    ...mapGetters(["isAdmin", "isPaidCustomer", "filters"]),
    isFloat(number) {
      return number % 1 !== 0;
    },
    conversionRate(plays, impressions) {
      const cr = impressions > 0 ? (plays / impressions) * 100 : 0;
      return cr.toFixed(2);
    },
    fillRate(plays, inventory) {
      const fr = inventory > 0 ? (plays / inventory) * 100 : 0;
      return fr.toFixed(2);
    },
    rpm(impressions, earnings) {
      const rpm = impressions > 0 ? (earnings / impressions) * 1000 : 0;
      return rpm.toFixed(2);
    },
    rpmPlays(plays, earnings) {
      const rpm = plays > 0 ? (earnings / plays) * 1000 : 0;
      return rpm.toFixed(2);
    },
    handleClick(item) {
      if (!this.rowClickable) return;

      let feedHash = "";

      if (item.hasOwnProperty(feedHash)) {
        feedHash = item.feedHash;
      } else if (item.hasOwnProperty("program")) {
        feedHash = item.program.id;
      }

      this.$store.dispatch(SET_FILTERS, {
        networks: [],
        programs: [feedHash]
      });
    },
    rowClass(item) {
      if (!item) return;

      let feedHash = "";

      if (item.hasOwnProperty(feedHash)) {
        feedHash = item.feedHash;
      } else if (item.hasOwnProperty("program")) {
        feedHash = item.program.id;
      }

      if (!this.filters().programs || this.filters().programs.length == 0)
        return;
      if (this.filters().programs.includes(feedHash)) {
        return "text-primary";
      }
    }
  }
};
</script>
