import { SET_FILTERS, SET_FILTERS_FIELD } from "@/store/stats.module";
import {
  SET_FILTERS_OPTIONS,
  SET_FILTERS_OPTIONS_ITEMS,
  SET_FILTERS_OPTIONS_LOADING
} from "@/store/filters.modules";
import { getProgramsV2 } from "@/api/programs/getters";
import { getTermsFilters } from "@/api/filters/getters";
import store from "../store";
import { getTimestamp } from "@/common/functions";
import { getAllDomainsPremiumV2 } from "@/api/domains/getters.js";

//Max 50 programs per request
const LIMIT_PROGRAMS = 100;

export const updateFilter = (filters, selected, $store, name) => {
  let params = { ...filters };
  params[name] = selected;

  $store.dispatch(SET_FILTERS_OPTIONS, { field: name, payload: selected });

  $store.dispatch(SET_FILTERS, params);
};

export const clearFilters = (filters, $store) => {
  const keys = Object.keys(filters);
  let params = {};
  keys.map(key => {
    if (key !== "dateRange") {
      $store.dispatch(SET_FILTERS_OPTIONS, {
        payload: [],
        field: key
      });

      $store.dispatch(SET_FILTERS_FIELD, { payload: [], field: key });
    }
  });

  $store.dispatch(SET_FILTERS, params);
};

export const loadFilter = async ({
  name,
  elasticSearchFilterField,
  dateRange,
  axios,
  searchFilter
}) => {
  setLoadingFilter(name, true);
  resetItemsFilter(name);

  let items = [];

  let filters = {
    start_date: getTimestamp(dateRange[0]),
    end_date: getTimestamp(dateRange[1], true),
    search: searchFilter,
    domains: store.state.stats.filters.domains,
    programs: store.state.stats.filters.programs
  };

  if (elasticSearchFilterField === "epsiode") {
    filters.programs = store.state.stats.filters.programs;
  }

  try {
    const field = elasticSearchFilterField;
    const dataFilter = await getTermsFilters(axios, field, {}, filters);

    dataFilter.forEach(value => {
      items.push({
        name: value,
        value: value
      });
    });
  } catch (error) {
    console.error(error);
  }

  setItemsFilter(name, items);
  setLoadingFilter(name, false);
};

export const loadPrograms = async (axios, searchFilters) => {
  const NAME = "programs";

  setLoadingFilter(NAME, true);

  let filters = {
    domains: store.state.stats.filters.domains,
    search: searchFilters,
    limit: LIMIT_PROGRAMS
  };

  let programs = [];

  try {
    const response = await getProgramsV2(filters, axios);

    if ("data" in response && Array.isArray(response.data)) {
      programs = response.data.map(row => {
        let name = row.name;

        const disabled = row.homeDisplay;
        const parentId = row.parentId;
        const shortened = shortened;

        if (parentId) {
          name = `${name} (old URL)`;
        } else if (!disabled) {
          name = `${name} (disabled)`;
        }

        return {
          name: name,
          id: row.id,
          urlHash: row.urlHash,
          disabled: row.homeDisplay,
          shortened: shortened,
          parentId: parentId
        };
      });
    }
  } catch (error) {
    console.error("---ERROR-PROGRAMS---");
    console.error(error);
  }

  setItemsFilter(NAME, programs);
  setLoadingFilter(NAME, false);
};

/**
 *
 * @param {String} field
 * @description Set items in field with an empty array
 */
const resetItemsFilter = field => {
  store.dispatch(SET_FILTERS_OPTIONS_ITEMS, {
    field,
    payload: []
  });
};

/**
 *
 * @param {String} field
 * @param {Boolean} isLoading
 * @description Set loading for a filter property
 */
const setLoadingFilter = (field, isLoading) => {
  store.dispatch(SET_FILTERS_OPTIONS_LOADING, {
    isLoading,
    field
  });
};

/**
 *
 * @param {String} field
 * @param {Array} payload
 * @description Set items for a filter property
 */
const setItemsFilter = (field, payload) => {
  store.dispatch(SET_FILTERS_OPTIONS_ITEMS, {
    field,
    payload
  });
};

export const loadDomains = async (axios, filtersOptions, searchFilter) => {
  const NAME = "domains";
  try {
    filtersOptions[NAME].loading = true;
    const response = await getAllDomainsPremiumV2(
      { page: 1, search: searchFilter },
      axios
    );
    filtersOptions[NAME].items = response.data;
    filtersOptions[NAME].loading = false;
  } catch (error) {
    console.error("---ERROR-LOAD-DOAMAINS---");
    console.error(error);
    filtersOptions[NAME].loading = false;
  }
};
