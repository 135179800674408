export const utmFilters = [
  {
    name: "utm_campaign",
    label: "UTM Campaign",
    valueField: "value",
    elasticSearchFilterField: "utm_campaign"
  },
  {
    name: "utm_source",
    label: "UTM Source",
    valueField: "value",
    elasticSearchFilterField: "utm_source"
  },
  {
    name: "utm_content",
    label: "UTM Content",
    valueField: "value",
    elasticSearchFilterField: "utm_content"
  },
  {
    name: "utm_medium",
    label: "UTM Medium",
    valueField: "value",
    elasticSearchFilterField: "utm_medium"
  }
];
