<template>
  <AsynCardContainer
    v-bind:sizes="{
      default: 12,
      sm: 12,
      lg: 12
    }"
    v-bind:loading="loading"
    :visibleCard="visibleCard"
  >
    <Table
      v-if="!loading"
      :fields="fields"
      v-bind:items="items"
      v-bind:rowClickable="rowClickable"
    ></Table>
  </AsynCardContainer>
</template>

<script>
import Table from "@/components/Table";
import AsynCardContainer from "./AsynCardContainer";
export default {
  name: "TableCard",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      }
    },
    rowClickable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    },
    visibleCard: {
      type: Boolean,
      required: false
    }
  },
  components: { Table, AsynCardContainer },
  methods: {},
  computed: {}
};
</script>
