<template>
  <b-dropdown
    v-if="!filtersOptions[filter.name].isError"
    variant="secondary"
    menu-class="p-0"
    toggle-class="btn btn-secondary dropdown-toggle border-0 bg-light"
    class="border-0"
  >
    <template #button-content>
      <b-spinner
        v-if="filtersOptions[filter.name].loading"
        class="text-muted mr-2"
        small
        label="Small Spinner"
      ></b-spinner>
      {{
        filters[filter.storeValue ? filter.storeValue : filter.name].length > 0
          ? filter.label +
            " (" +
            filters[filter.storeValue ? filter.storeValue : filter.name]
              .length +
            ")"
          : filter.label
      }}
    </template>

    <Autocomplete
      v-model="selected"
      :dropdown="false"
      :items="filtersOptions[filter.name].items"
      :valueField="filter.valueField"
      :selected="filtersOptions[filter.name].selected"
      :placeholder="`Search ${filter.name}`"
      :filterName="filter.name"
      :isSearchBack="filter.isSearchBack"
      :isMultipleSelect="filter.isMultipleSelect"
      :filters="filters"
      @input="updateFilter"
      @searchBack="loadFilterBack"
      :displayText="filter.displayText"
    />
  </b-dropdown>
</template>

<script>
import Autocomplete from "@/components/autocomplete2";
import {
  updateFilter,
  loadFilter,
  loadPrograms,
  clearFilters,
  loadDomains
} from "@/common/filters";
import { mapGetters } from "vuex";
import { SET_FILTERS_OPTIONS_ITEMS } from "@/store/filters.modules";
import {
  filtersToChange,
  trigersFiltersChange
} from "@/common/config/filters/FilterChange";
import { getCampaigns } from "../../../../api/campaigns/manage/getters";
import axios from "axios";

export default {
  components: {
    Autocomplete
  },

  props: {
    filter: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      selected: [],
      timeout: 0,
      searchBacKFilter: ["programs"]
    };
  },

  computed: {
    ...mapGetters(["filters", "dateRange", "filtersOptions", "currentUser"]),

    domains() {
      return this.currentUser.domains;
    }
  },

  beforeDestroy() {
    clearFilters(this.filters, this.$store);
  },

  beforeMount() {
    // The setTimeout must remain, because of "inputDate" taking time to set and the campaigns
    setTimeout(() => {
      if (this.filter.name !== "modes") {
        this.loadFilters();
      }
    }, 1000);

    //Global event from the components 'clearFilters'
    this.$root.$on("clear", () => {
      if (filtersToChange.includes(this.filter.name)) {
        this.loadFilters();
      }
    });
  },

  methods: {
    loadFilters(searchFilter = null) {
      this.loadFilter(
        this.filter.name,
        this.filter.elasticSearchFilterField
          ? this.filter.elasticSearchFilterField
          : this.filter.name,
        searchFilter
      );
    },

    updateFilter() {
      updateFilter(this.filters, this.selected, this.$store, this.filter.name);
    },

    async loadFilter(name, elasticSearchFilterField, searchFilter) {
      switch (name) {
        case "programs":
          loadPrograms(this.axios, searchFilter);
          break;
        case "campaigns":
          this.loadCampaigns();
          break;
        case "domains":
          loadDomains(this.axios, this.filtersOptions, searchFilter);
          break;
        default:
          loadFilter({
            name,
            elasticSearchFilterField,
            dateRange: this.dateRange,
            axios: this.axios,
            searchFilter
          });
          break;
      }
    },

    async loadCampaigns() {
      const campaigns = await getCampaigns(
        axios,
        this.filtersOptions["campaigns"].params
      );
      this.$store.dispatch(SET_FILTERS_OPTIONS_ITEMS, {
        field: this.filter.name,
        payload: campaigns.rows
      });
    },

    loadFilterBack(searchFilter) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.loadFilters(searchFilter);
      }, 500);
    },

    getFilters() {
      let filters = {};
      for (const key in this.filters) {
        const element = this.filters[key];
        if (element.length > 0 && key !== "dateRange") {
          filters[key] = this.filters[key];
        }
      }
      return filters;
    },

    getFilterAsChange(oldFilter, newFilter) {
      let field = "";
      for (let keys in oldFilter) {
        if (newFilter[keys] && oldFilter[keys] !== newFilter[keys]) {
          field = keys;
        }
      }
      return field;
    }
  },

  watch: {
    filters(oldFilter, newFilter) {
      const fieldFilter = this.getFilterAsChange(oldFilter, newFilter);
      if (
        trigersFiltersChange.includes(fieldFilter) &&
        filtersToChange.includes(this.filter.name) &&
        fieldFilter !== this.filter.name
      ) {
        this.loadFilters();
      }
      //Only for reseting "selected" when the user clear the filters
      if (this.filtersOptions[this.filter.name].selected.length === 0) {
        this.selected = [];
      }
    },

    dateRange() {
      if (
        this.filter.name.includes("utm") ||
        this.filter.name.includes("episodes") ||
        this.filter.name.includes("devices")
      ) {
        this.loadFilters();
      }
    }
  }
};
</script>
