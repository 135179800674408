<template>
  <div class="auto-complete2 p-0 pt-0 mt-0" style="max-width: 320px">
    <div class="p-1">
      <span
        style="position: absolute; top: 9px; left: 8px"
        class="kt-svg-icon kt-svg-icon--dark"
      >
        <inline-svg src="assets/media/icons/svg/General/Search.svg" />
      </span>
      <b-form-input
        size="sm"
        style="font-size: 14px; padding-left: 30px"
        v-model="inputValue"
        @input="getSearchBack"
        @focus="open = true"
        :placeholder="placeholder"
      />
    </div>
    <ul
      class="hidden-scrollbar pt-0"
      style="width:100%; max-height: 240px;overflow:scroll"
    >
      <b-dropdown-text
        v-if="isActivateToggleAll"
        class="dropdown-item pt-1 pb-0"
        style="border-bottom: 1px solid #eee; background-color: #fafafa; font-weight: 600;"
        @click="toggleAll"
        >{{
          blacklist.length == 0 ? "Select All" : "Unselect All"
        }}</b-dropdown-text
      >

      <template v-if="isItemsEmpty">
        <b-dropdown-text
          class="dropdown-item"
          style="border-bottom: 1px solid #fff;"
          >No {{ filterName }} found</b-dropdown-text
        >
      </template>

      <b-dropdown-text
        class="dropdown-item active"
        style="border-bottom: 1px solid #fff;"
        @click="clickItem(item, $event)"
        v-for="(item, index) in itemsSelected"
        :key="`selected_${index}`"
        >{{ item.name }}</b-dropdown-text
      >

      <b-dropdown-text
        class="dropdown-item"
        style="border-bottom: 1px solid #fff;"
        @click="clickItem(item, $event)"
        :key="`item_${index}`"
        v-for="(item, index) in filteredItems"
        >{{ item.name }}</b-dropdown-text
      >
    </ul>
  </div>
</template>

<script>
export default {
  name: "autocomplete",
  props: {
    items: {
      type: Array,
      default: () => {
        return [];
      }
    },
    value: {
      type: Array,
      default: () => {
        return [];
      }
    },
    selected: {
      type: Array,
      default: () => {
        return [];
      }
    },
    valueField: {
      type: String,
      default: "externalId"
    },
    placeholder: {
      type: String,
      default: "externalId"
    },
    isMultipleSelect: {
      type: Boolean,
      required: false,
      default: true
    },
    isSearchBack: {
      type: Boolean,
      default: false
    },
    filterName: {
      type: String,
      default: ""
    },
    displayText: {
      type: String,
      default: "name"
    },
    filters: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      inputValue: "",
      open: true,
      blacklist: [],
      itemsSelected: []
    };
  },
  components: {},
  computed: {
    isActivateToggleAll() {
      return this.items.length >= 1 && this.isMultipleSelect;
    },

    filteredItems() {
      let items = this.items;

      if (this.itemsSelected.length > 0) {
        items = this.itemsWithNoSelected;
      }

      if (this.inputValue && this.items.length > 0) {
        items = items.filter(network => {
          return (network.name
            ? network.name
            : network.title
            ? network.title
            : ""
          )
            .toLowerCase()
            .includes(this.inputValue.toLowerCase());
        });
      }

      return items;
    },

    itemsWithNoSelected() {
      let items = [...this.items];
      for (let index = 0; index < this.itemsSelected.length; index++) {
        const getItem = this.itemsSelected[index];
        items = items.filter(
          element => element[this.valueField] !== getItem[this.valueField]
        );
      }

      return items;
    },

    isItemsEmpty() {
      return (
        this.filteredItems.length === 0 &&
        this.items.length === 0 &&
        !this.isFiltersLoading
      );
    },

    isFilteredItemsEmpty() {
      return this.filteredItems.length === 0;
    },

    isFiltersLoading() {
      return this.$store.state.filters.filtersOptions[this.filterName].loading;
    }
  },

  mounted() {
    this.open = true;

    //Global event from the components 'clearFilters'
    this.$root.$on("clear", () => {
      this.inputValue = "";
      this.itemsSelected = [];
    });
  },

  created() {
    this.blacklist = this.value;
    this.open = true;

    setTimeout(() => {
      this.$watch(
        "filter.domains",
        () => {
          const find = this.items.find(element => {
            if (element.id) {
              return element.id === this.filters[this.filterName][0];
            }
          });
          if (find) {
            this.itemsSelected.push(find);
            this.blacklist.push(find.id);
          }
        },
        { immediate: true }
      );
    }, 2000);
  },

  methods: {
    toggleAll() {
      this.blacklist = this.value;
      if (this.blacklist.length > 0) {
        this.blacklist = [];
        this.itemsSelected = [];
        this.$emit("input", this.blacklist);
      } else {
        this.blacklist = this.items.map(item => {
          return item[this.valueField];
        });
        this.$emit("input", this.blacklist);
      }
    },

    clickItem(itemSelected) {
      const value = itemSelected[this.valueField];

      if (this.blacklist.includes(value)) {
        this.blacklist = [...this.blacklist.filter(item => item !== value)];
        const indexItemSelected = this.itemsSelected.findIndex(
          element => element[this.valueField] === itemSelected[this.valueField]
        );
        this.itemsSelected.splice(indexItemSelected, 1);
      } else {
        this.itemsSelected.push(itemSelected);

        if (this.isMultipleSelect) {
          this.blacklist = [...this.blacklist, value];
        } else {
          this.blacklist = [value];
          this.itemsSelected = [itemSelected];
        }
      }

      this.$emit("input", this.blacklist);
    },

    getSearchBack() {
      if (this.isSearchBack) {
        this.$emit("searchBack", this.inputValue.toLowerCase());
      }
    }
  }
};
</script>

<style scoped>
.auto-complete2 label {
  margin-bottom: 0;
  margin-left: 6px;
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
.auto-complete2 .dropdown-item {
  padding: 0.7rem 1.5rem;
  cursor: pointer;
}

.auto-complete2 .dropdown-item p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.hidden-scrollbar {
  padding-left: 0px !important;
}
</style>
