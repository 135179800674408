<template>
  <v-row class="row-cards">
    <StatsCards
      style="cursor:pointer"
      v-for="(tab, key) in tabs"
      :key="key"
      v-bind="tab"
      v-on:click.native="selectTab(key)"
    ></StatsCards>
  </v-row>
</template>

<script>
import StatsCards from "@/views/partials/content/cards/Stats";

export default {
  name: "TabsCards",
  props: {
    tabs: {
      type: Array
    }
  },

  components: { StatsCards },

  computed: {},
  methods: {
    selectTab(key) {
      if (!this.tabs[key].selected) {
        this.$emit("change", key);
      }
    }
  }
};
</script>
