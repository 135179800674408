import i18n from "@/common/plugins/vue-i18n";

export const headers = [
  {
    label: i18n.t("DASHBOARD_CONVERSION.DATATABLE.DOMAIN"),
    key: "domain",
    sortable: false
  },
  {
    label: i18n.t("DASHBOARD_CONVERSION.DATATABLE.PLAYS"),
    key: "plays",
    sortable: true,
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(item.plays);
    }
  },
  {
    label: i18n.t("DASHBOARD_CONVERSION.DATATABLE.SESSIONS"),
    key: "sessions",
    sortable: true,
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(item.sessions);
    }
  },
  {
    label: i18n.t("DASHBOARD_CONVERSION.DATATABLE.SESSIONS_RATE"),
    key: "sessions_rate",
    sortable: true,
    formatter: (value, key, item) => {
      return `${new Intl.NumberFormat(navigator.language).format(
        item.sessions_rate * 100
      ) || 0} %`;
    }
  },
  {
    label: i18n.t("DASHBOARD_CONVERSION.DATATABLE.PAGEVIEWS"),
    key: "pages",
    sortable: true,
    formatter: (value, key, item) => {
      return new Intl.NumberFormat(navigator.language).format(item.pages);
    }
  },
  {
    label: i18n.t("DASHBOARD_CONVERSION.DATATABLE.PAGEVIEWS_RATE"),
    key: "pages_rate",
    sortable: true,
    formatter: (value, key, item) => {
      return `${new Intl.NumberFormat(navigator.language).format(
        item.pages_rate * 100
      ) || 0} %`;
    }
  }
];

const normalizeExportData = datas => {
  let table = [];
  for (const domain in datas) {
    const stats = datas[domain];
    table.push({
      domain,
      plays:
        new Intl.NumberFormat(navigator.language).format(stats["plays"]) || 0,
      sessions:
        new Intl.NumberFormat(navigator.language).format(stats["sessions"]) ||
        0,
      sessions_rate:
        (new Intl.NumberFormat(navigator.language).format(
          stats["sessions:rate"] * 100
        ) || 0) + " %",
      pages:
        new Intl.NumberFormat(navigator.language).format(stats["pages"]) || 0,
      pages_rate:
        new Intl.NumberFormat(navigator.language).format(
          stats["pages:rate"] * 100
        ) || 0
    });
  }
  return table;
};

const normalizeDataTable = datas => {
  let table = [];
  for (const domain in datas) {
    const stats = datas[domain];
    table.push({
      domain,
      plays: stats["plays"],
      sessions: stats["sessions"],
      sessions_rate: stats["sessions:rate"],
      pages: stats["pages"],
      pages_rate: stats["pages:rate"]
    });
  }
  return table;
};

export const tabs = [
  {
    loading: true,
    title: "Plays",
    primaryStat: "0",
    previousCompare: "0",
    iconSVG: "assets/media/icons/svg/Media/Play3.svg",
    iconSVGColor: "primary",
    cardClass: "bg-pi",
    value: "plays",
    selected: true,
    tooltip: {
      descriptionKey: "DASHBOARD_CONVERSION.CARDS.TOOLTIPS.PLAYS"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x);
    },
    normalizeDataTable: function(datas) {
      return normalizeDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    chartOptions: {
      chartType: "area"
    },
    chartDataToDisplay: [
      {
        value: "plays",
        color: "rgba(255,150,29,0.3)",
        borderColor: "#FF961D",
        label: "Plays"
      }
    ]
  },
  {
    loading: true,
    title: "Sessions",
    primaryStat: "0",
    previousCompare: "0",
    iconSVG: "assets/media/icons/svg/Media/Airplay.svg",
    iconSVGColor: "secondary",
    cardClass: "bg-pi",
    value: "sessions",
    selected: false,
    tooltip: {
      descriptionKey: "DASHBOARD_CONVERSION.CARDS.TOOLTIPS.SESSIONS"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x);
    },
    normalizeDataTable: function(datas) {
      return normalizeDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    chartOptions: {
      chartType: "area"
    },
    chartDataToDisplay: [
      {
        value: "sessions",
        color: "rgba(255,150,29,0.3)",
        borderColor: "#FF961D",
        label: "Sessions"
      }
    ]
  },
  {
    loading: true,
    title: "Session Conversion Rate",
    primaryStat: "0 %",
    previousCompare: "0 %",
    iconSVG: "assets/media/icons/svg/Shopping/Chart-pie.svg",
    iconSVGColor: "warning",
    cardClass: "bg-pi",
    value: "sessions:rate",
    selected: false,
    tooltip: {
      descriptionKey: "DASHBOARD_CONVERSION.CARDS.TOOLTIPS.SESSIONSRATE"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x * 100) + " %";
    },
    normalizeDataTable: function(datas) {
      return normalizeDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    chartOptions: {
      chartType: "area"
    },
    chartDataToDisplay: [
      {
        value: "sessions:rate",
        color: "rgba(255,150,29,0.3)",
        borderColor: "#FF961D",
        label: "Sessions Rate"
      }
    ]
  },
  {
    loading: true,
    title: "Pages",
    primaryStat: "0",
    previousCompare: "0",
    iconSVG: "assets/media/icons/svg/Media/Playlist2.svg",
    iconSVGColor: "secondary",
    cardClass: "bg-pi",
    value: "pages",
    selected: false,
    tooltip: {
      descriptionKey: "DASHBOARD_CONVERSION.CARDS.TOOLTIPS.PAGES"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x);
    },
    normalizeDataTable: function(datas) {
      return normalizeDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    chartOptions: {
      chartType: "area"
    },
    chartDataToDisplay: [
      {
        value: "pages",
        color: "rgba(255,150,29,0.3)",
        borderColor: "#FF961D",
        label: "Pages"
      }
    ]
  },
  {
    loading: true,
    title: "Page Conversion Rate",
    primaryStat: "0 %",
    previousCompare: "0 %",
    iconSVG: "assets/media/icons/svg/Shopping/Chart-pie.svg",
    iconSVGColor: "warning",
    cardClass: "bg-pi",
    value: "pages:rate",
    selected: false,
    tooltip: {
      descriptionKey: "DASHBOARD_CONVERSION.CARDS.TOOLTIPS.PAGESRATE"
    },
    renderFigure: function(x) {
      return new Intl.NumberFormat(navigator.language).format(x * 100) + " %";
    },
    normalizeDataTable: function(datas) {
      return normalizeDataTable(datas);
    },
    normalizeExportData: function(datas) {
      return normalizeExportData(datas);
    },
    chartOptions: {
      chartType: "area"
    },
    chartDataToDisplay: [
      {
        value: "pages:rate",
        color: "rgba(255,150,29,0.3)",
        borderColor: "#FF961D",
        label: "Page Rate"
      }
    ]
  }
];
