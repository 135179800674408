export const filtersToChange = [
  "programs",
  "episodes",
  "devices",
  "utm_campaign",
  "utm_source",
  "utm_content",
  "utm_medium"
];

export const trigersFiltersChange = ["domains", "programs"];
