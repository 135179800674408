import { get } from "@/api/getApi1.0";
import { get as getV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getAllDomainsPremium = axios =>
  get(
    {
      path: ENTITY_NAME_IN_URI.DOMAINS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.DOMAINS.name
        }
      ]
    },
    {},
    axios
  );

export const getAllDomainsPremiumV2 = (filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.DOMAINS.path
    },
    filters,
    axios
  );
