import { get as getV2, update as updateV2 } from "@/api/getApi2.0";
import { ENTITY_NAME_IN_URI as ENTITY_NAME_IN_URI_V2 } from "@/api/config/api2.0";

export const getProgramsV2 = (filter, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.path
    },
    filter,
    axios
  );

export const getProgramsByCompany = (companyId, filter, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.PROGRAMS.companies,
      ressources: [
        {
          name: companyId,
          value: ENTITY_NAME_IN_URI_V2.PROGRAMS.path
        }
      ]
    },
    filter,
    axios
  );

export const exportProgramsTags = axios =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.EXPORT.PROGRAMS.path
    },
    {},
    axios
  );

export const getItunesCategories = axios =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.ITUNES_CATEGORIES.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI_V2.ITUNES_CATEGORIES.value
        }
      ]
    },
    {},
    axios
  );

export const getAllEpisodes = (filters, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.EPISODES.path
    },
    filters,
    axios
  );

export const getEpisodesFromPrograms = (filters, programId, axios) =>
  getV2(
    {
      path: ENTITY_NAME_IN_URI_V2.EPISODES_PROGRAMS.path,
      ressources: [
        {
          name: programId,
          value: ENTITY_NAME_IN_URI_V2.EPISODES_PROGRAMS.name
        }
      ]
    },
    filters,
    axios
  );

export const updateProgramV2 = (axios, id, data) =>
  updateV2(
    {
      path: `${ENTITY_NAME_IN_URI_V2.PROGRAMS.path}/${id}`
    },
    data,
    axios
  );
