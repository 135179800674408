<template>
  <svg
    id="rf"
    enable-background="new 0 0 512.004 512.004"
    width="14"
    height="14"
    viewBox="0 0 512.004 512.004"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path :d="path11 + path12 + path13 + path14 + path15" />
      <path :d="path21 + path22 + path23" />
    </g>
  </svg>
</template>

<script>
export default {
  data() {
    return {
      path11:
        "m361.183 0c-54.059 0-99.836 36.049-114.505 85.331h-192.948c-18.024 0-28.614 20.339-18.285 35.119.16.231-5.363-7.31",
      path12:
        " 129.747 177.039 2.714 3.951 4.148 8.57 4.148 13.367v177.688c0 19.435 22.224 30.24 37.473 18.754l57.593-43.518c8.614-6.415 13.754-16.655 13.754-27.409v-125.515c0-4.798 1.435-9.417",
      path13:
        " 4.149-13.369l46.497-63.451c76.139 21.439 151.81-36.022 151.81-114.791.001-65.752-53.577-119.245-119.433-119.245zm-103.192",
      path14:
        " 279.919c-5.835 7.968-9.831 19.1-9.831 30.938 0 136.483.734 127.081-1.68 128.869-1.91 1.421 10.835-8.188-47.14 35.618v-164.488c0-11.012-3.327-21.608-9.622-30.646-.169-.242 4.923",
      path15:
        " 6.71-120.835-164.88h172.938c-1.457 44.852 22.126 84.961 58.678 106.581zm103.192-71.428c-49.314 0-89.434-40.035-89.434-89.246 0-49.21 40.12-89.245 89.434-89.245 49.313 0 89.433 40.035 89.433 89.245.001 49.211-40.119 89.246-89.433 89.246z",
      path21:
        "m400.201 80.298c-5.854-5.864-15.35-5.872-21.213-.02l-17.805 17.773-17.805-17.773c-5.863-5.853-15.361-5.846-21.213.02-5.853 5.862-5.844 15.36.019 21.213l17.767 17.735-17.767",
      path22:
        " 17.735c-5.863 5.853-5.872 15.351-.019 21.213 5.833 5.844 15.331 5.891 21.213.02l17.805-17.773 17.805 17.773c5.845 5.834 15.343 5.862 21.213-.02 5.853-5.862 5.844-15.36-.019-21.213l-17.767-17.735",
      path23: "17.767-17.735c5.863-5.853 5.872-15.351.019-21.213z"
    };
  },
  components: {},
  props: {},
  methods: {}
};
</script>
