<template>
  <div>
    <a
      v-if="isLoading"
      @click="downloadCsv"
      id="externalButton"
      class="btn btn-icon btn-light btn-sm"
    >
      <span class="kt-svg-icon kt-svg-icon--primary">
        <inline-svg src="assets/media/icons/svg/Files/DownloadedFile.svg" />
      </span>
    </a>

    <download-csv
      v-if="isLoading"
      style="display: none"
      :data="jsonData"
      :name="getCsvname"
    >
      <button ref="myBtn">
        Download Data
      </button>
    </download-csv>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getQueryFilters } from "@/common/functions";

export default {
  data() {
    return {
      data: []
    };
  },

  props: {
    apiSlug: {
      type: String,
      required: false
    },
    concatFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    formatedData: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  mounted() {
    this.$root.$on("exportData", data => {
      this.data = data;
    });
  },

  beforeDestroy() {
    this.$root.$off("exportData", (this.data = []));
  },

  computed: {
    ...mapGetters(["filters", "header"]),

    isLoading() {
      return !(this.data.length === 0 && this.formatedData.length === 0);
    },

    jsonData() {
      let data = [];
      if (this.data.length >= 1) {
        data = this.data;
      }

      if (this.formatedData.length >= 1) {
        data = this.formatedData;
      }

      return data;
    },

    getCsvname() {
      if (this.filters.dateRange && this.filters.dateRange.length > 1) {
        let filters = {
          dashboard: this.APIslug,
          start_date: this.filters.dateRange[0].substring(0, 10),
          end_date:
            new Date(this.filters.dateRange[1]) > new Date()
              ? new Date().getTime() / 1000
              : this.filters.dateRange[1].substring(0, 10)
        };

        let str =
          this.apiSlug +
          "_" +
          this.filters.dateRange[0].substring(0, 10) +
          "_" +
          this.filters.dateRange[1].substring(0, 10);

        let filtersValues = getQueryFilters(filters, this.concatFilters);

        delete filtersValues.dashboard;
        delete filtersValues.start_date;
        delete filtersValues.end_date;

        str += Object.keys(filtersValues)
          .map(function(key) {
            return key + "=" + filtersValues[key];
          })
          .join("_");

        str = str + ".csv";
        return str.replaceAll(" ", "_");
      }
      return "data.csv";
    }
  },

  methods: {
    downloadCsv() {
      var elem = this.$refs.myBtn;

      if (elem) {
        elem.click();
      }
    }
  }
};
</script>
