<template>
  <div>
    <FiltersTemplate
      :inputFilters="inputFilters"
      :adminFilters="adminFilters"
    />

    <AlertExpirePassword />

    <ConversionLayout
      v-bind:displayCreate="false"
      v-bind:displayChart="true"
      v-bind:APIslug="'conversion'"
      v-bind:tabsData="tabsData"
      v-bind:inputFilters="inputFilters"
      v-bind:adminFilters="adminFilters"
      class="kt-container"
    ></ConversionLayout>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import AlertExpirePassword from "@/components/AlertExpirePassword";
import FiltersTemplate from "@/components/subheaders/filters/FiltersTemplate";
import ConversionLayout from "@/components/layout/ConversionLayout";
import { tabs } from "@/common/config/dashboard/conversion";
import { adminFilters, inputFilters } from "@/common/config/filters/conversion";

export default {
  name: "Conversion",

  components: {
    ConversionLayout,
    FiltersTemplate,
    AlertExpirePassword
  },

  data() {
    return {
      tabsData: tabs,
      adminFilters: adminFilters,
      inputFilters: inputFilters
    };
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Conversion",
        pageCategory: "Analytics"
      }
    ]);
  },

  computed: {},

  watch: {},

  methods: {}
};
</script>
