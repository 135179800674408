export const adminFilters = [];

export const inputFilters = [
  {
    name: "domains",
    label: "All Domains",
    storeValue: "domains",
    elasticSearchFilterField: "domain",
    displayText: "name",
    valueField: "id",
    isSearchBack: false,
    isMultipleSelect: true
  },
  {
    name: "devices",
    label: "All Devices",
    storeValue: "devices",
    elasticSearchFilterField: "device",
    displayText: "name",
    valueField: "value",
    isSearchBack: false,
    isMultipleSelect: true
  },
  {
    name: "browsers",
    label: "All Browsers",
    storeValue: "browsers",
    elasticSearchFilterField: "browser",
    displayText: "name",
    valueField: "value",
    isSearchBack: false,
    isMultipleSelect: true
  },
  {
    name: "page_type",
    label: "All PageTypes",
    storeValue: "page_type",
    elasticSearchFilterField: "page_type",
    displayText: "name",
    valueField: "value",
    isSearchBack: false,
    isMultipleSelect: true
  },
  {
    name: "modes",
    label: "All Modes",
    elasticSearchFilterField: "mode",
    displayText: "name",
    valueField: "value",
    isSearchBack: false,
    isMultipleSelect: false
  }
];
