import { render, staticRenderFns } from "./Stats.vue?vue&type=template&id=3c7d05bc&scoped=true&"
import script from "./Stats.vue?vue&type=script&lang=js&"
export * from "./Stats.vue?vue&type=script&lang=js&"
import style0 from "./Stats.vue?vue&type=style&index=0&id=3c7d05bc&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c7d05bc",
  null
  
)

export default component.exports