<template>
  <SubHeaderContainer>
    <div class="kt-subheader__main">
      <template v-if="isAdmin">
        <InputFilter
          v-for="(item, index) in adminFilters"
          :key="`filter_admin_${index}`"
          :filter="item"
        />
      </template>

      <InputFilter
        v-for="(item, index) in inputFilters"
        :key="`filter_input_${index}`"
        :filter="item"
      />

      <span
        @click="toggleFilters"
        class="text-muted ml-2"
        style="cursor:pointer"
        :class="!displayAllFilters ? 'flaticon2-down' : 'flaticon2-up'"
      ></span>

      <ClearFilters />

      <div v-show="displayAllFilters">
        <UtmFilters />
      </div>
    </div>

    <div class="d-flex">
      <InputDate />

      <DownloadButton :apiSlug="apiSlug" :concatFilters="concatFilters" />
    </div>
  </SubHeaderContainer>
</template>

<script>
import SubHeaderContainer from "@/components/subheaders/SubHeaderContainer";
import UtmFilters from "@/components/subheaders/UtmFilters";
import InputFilter from "@/components/subheaders/filters/inputs/InputFilter";
import InputDate from "@/components/subheaders/filters/inputs/InputDate";
import ClearFilters from "@/components/subheaders/filters/ClearFilters";
import DownloadButton from "@/components/subheaders/filters/inputs/DownloadButton";
import { utmFilters } from "@/common/config/filters/UtmFilters";
import { mapGetters } from "vuex";

export default {
  components: {
    SubHeaderContainer,
    UtmFilters,
    InputFilter,
    InputDate,
    ClearFilters,
    DownloadButton
  },

  props: {
    inputFilters: {
      type: Array,
      required: true
    },
    adminFilters: {
      type: Array,
      required: false,
      default: () => []
    },
    apiSlug: {
      type: String,
      required: false
    }
  },

  computed: {
    ...mapGetters(["isAdmin"]),

    concatFilters() {
      return this.adminFilters.concat(this.inputFilters, utmFilters);
    }
  },

  data() {
    return {
      displayAllFilters: false
    };
  },

  methods: {
    toggleFilters() {
      this.displayAllFilters = !this.displayAllFilters;
    }
  }
};
</script>
