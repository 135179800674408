<template>
  <div :class="cardSize" class="card-pi-stats" style="min-width: 180px;">
    <div class="card border-0" :class="getWaveClass">
      <div
        class="card-body align-middle"
        style="padding: 30px"
        :class="{ 'active-tabs': selected }"
      >
        <div
          v-if="loading"
          class="d-flex align-items-center justify-content-center pt-5"
        >
          <b-spinner variant="primary" label="Loading"></b-spinner>
        </div>

        <div class="row" v-if="!loading">
          <div class="col-12 mb-2 mt-0 pt-3 pb-1">
            <template v-if="vertical">
              <div class="icon" v-if="icon">
                <span :class="icon"></span>
              </div>

              <div class="d-flex d-inline-flex align-items-center mb-2">
                <div
                  v-if="iconSVG"
                  class="kt-svg-icon kt-svg-icon--lg d-flex d-inline-flex align-items-center"
                  :class="getSvgColor"
                >
                  <inline-svg :src="iconSVG" />
                </div>
              </div>

              <div
                class="d-flex align-items-center flex-column flex-md-row mb-1"
              >
                <h2 v-if="value !== 'cr'" class="title-card mb-0">
                  {{ primaryStat.toString() }}
                </h2>

                <span
                  v-if="trend"
                  class="font-weight-bold ml-2"
                  style="font-size: 1.7em;"
                  :class="getPercentTrend.class"
                  >{{ getPercentTrend.text }}</span
                >
              </div>

              <h2
                style="font-weight: 600; font-size: 1.5em; max-width: 150px"
                v-if="value == 'cr'"
              >
                {{ title }}
              </h2>
              <h5
                v-if="value !== 'cr'"
                class="card-title m-0 p-0 text-withe text-lowercase text-small"
                style="font-size: 12px; font-weight: 400"
              >
                {{ title }}
              </h5>
            </template>

            <template v-else>
              <div>
                <span class="primary-stats">{{ primaryStat }}</span>
                <span
                  class="secondary-stats ml-3"
                  v-if="previousCompare"
                  :class="getPreviousOrientationClass"
                >
                  {{ previousCompare }}
                  <span
                    :class="{
                      'fas fa-caret-up': previousOrientation === 'up',
                      'fas fa-caret-down': previousOrientation === 'down'
                    }"
                  ></span>
                </span>
                <h5 class="card-titlem-0 p-0">{{ title }}</h5>
              </div>
            </template>
          </div>

          <div class="d-flex w-100">
            <v-tooltip
              v-if="tooltip"
              top
              :open-on-click="!isHoverTooltip"
              :open-on-hover="isHoverTooltip"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ml-auto" icon v-bind="attrs" v-on="on">
                  <v-icon color="blue">
                    mdi-help-circle
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $tc(tooltip.descriptionKey) }}</span>
            </v-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StatsCard",
  props: {
    /**
     * String title
     */
    sizes: {
      type: Object
    },
    title: {
      type: String,
      required: true
    },
    primaryStat: {
      type: String || Number,
      required: true
    },
    previousCompare: String,
    previousOrientation: String,
    icon: String,
    iconSVG: String,
    iconSVGColor: String,
    value: String,
    chartDataToDisplay: Array,
    renderFigure: Function,
    cardClass: String,
    theme: String,
    vertical: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: Object,
      default: () => {},
      required: false
    },
    trend: {
      type: Number,
      required: false,
      default: 0
    }
  },
  components: {},
  methods: {},
  computed: {
    isHoverTooltip() {
      const breakpoint = this.$vuetify.breakpoint.name;
      if (breakpoint === "xs" || breakpoint === "sm") {
        return false;
      }

      return true;
    },

    cardSize() {
      if (this.sizes) {
        let elementClass = "";
        Object.entries(this.sizes).forEach(([type, value]) => {
          if (type === "default") {
            elementClass += "col-" + value + " ";
          } else {
            elementClass += "col-" + type + "-" + value + " ";
          }
        });
        return elementClass;
      } else {
        return "col";
      }
    },

    getSvgColor() {
      return `kt-svg-icon--${this.iconSVGColor}`;
    },

    getWaveClass() {
      let classe = "";

      if (this.trend && this.trend < 0) {
        classe += "kt-iconbox--wave wave-danger";
      } else if (this.trend) {
        classe += "kt-iconbox--wave wave-success";
      }

      return classe;
    },

    getPreviousOrientationClass() {
      switch (this.previousOrientation) {
        case "up":
          return " kt-font-success";
        case "down":
          return "kt-font-danger";
        case "equal":
          return "kt-font-warning";
        default:
          return "";
      }
    },

    getPercentTrend() {
      let percent = {
        text: "",
        class: ""
      };

      if (this.trend && this.trend > 0) {
        percent.text = `+${this.trend}%`;
        percent.class = "text-success";
      } else if (this.trend) {
        percent.text = `${this.trend}%`;
        percent.class = "text-danger";
      }

      return percent;
    }
  }
};
</script>

<style scoped lang="scss">
.wave-danger {
  background-color: rgba(246, 78, 96, 0.1);
}

.wave-success {
  background-color: rgba(27, 197, 189, 0.1);
}

.wave-primary {
  background-color: rgba(54, 153, 255, 0.1);
}

.active-tabs {
  border: solid #ff961d;
  border-image: linear-gradient(to top right, #ff4867, #ff961d, #fff01f) 1;
  border-radius: 0.75rem;
}

.title-card {
  font-weight: 600;
  font-size: 1.7em;
}
</style>
