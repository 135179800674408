<template>
  <div>
    <span v-for="filter in utmFilters" :key="filter.name">
      <InputFilter :filter="filter" />
    </span>
  </div>
</template>

<script>
import InputFilter from "@/components/subheaders/filters/inputs/InputFilter";
import { utmFilters } from "@/common/config/filters/UtmFilters";

export default {
  name: "UtmFilters",

  components: {
    InputFilter
  },

  data() {
    return {
      utmFilters: utmFilters
    };
  }
};
</script>
