import { get, post, postWithCancel, update, remove } from "@/api/getApi1.0";
import { ENTITY_NAME_IN_URI } from "@/api/config/api";

// podcasts from redis

export const getPodcasts = (filters, axios) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path
    },
    filters,
    axios
  );

export const getPodcast = (feedHash, axios) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path,
      ressources: [
        {
          name: feedHash
          // value: feedHash
        }
      ]
    },
    {},
    axios
  );

export const deletePodcast = (feedHash, axios) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path,
      ressources: [
        {
          name: feedHash
        }
      ]
    },
    {},
    axios
  );

export const resetattemptPodcast = (feedHash, data, axios) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path,
      ressources: [
        {
          name: feedHash,
          value: ENTITY_NAME_IN_URI.PODCASTS.RESET_ATTEMPT.path
        }
      ]
    },
    data,
    axios
  );

export const forceparsingPodcast = (feedHash, data, axios) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path,
      ressources: [
        {
          name: feedHash,
          value: ENTITY_NAME_IN_URI.PODCASTS.FORCE_PARSING.path
        }
      ]
    },
    data,
    axios
  );

export const forceParsingNow = (data, axios) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path,
      ressources: [
        {
          name: ENTITY_NAME_IN_URI.PODCASTS.FORCE_EXECUTING_NOW.path
        }
      ]
    },
    {},
    data,
    axios
  );

export const getProgramEpisodes = (feedHash, filters, axios) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.PODCASTS.path,
      ressources: [
        {
          name: feedHash,
          value: ENTITY_NAME_IN_URI.PODCASTS.EPISODES.path
        }
      ]
    },
    filters,
    axios
  );

// users

export const getUsers = (filters, axios) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.USERS.path
    },
    filters,
    axios
  );

export const setFeedToUser = (data, axios) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.USERS.path
    },
    {},
    data,
    axios
  );

// conversion

export const dashboardStats = (type, suffix, data, axios, options) =>
  postWithCancel(
    {
      path: ENTITY_NAME_IN_URI.STATS.path,
      ressources: [
        {
          name: type,
          value: suffix
        }
      ]
    },
    {},
    data,
    axios,
    options
  );

// programs

export const getPrograms = (filters, axios) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path
    },
    filters,
    axios
  );

export const getProgram = (axios, filters = null) =>
  get(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path
    },
    filters,
    axios
  );

export const createProgram = (axios, data) =>
  post(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path
    },
    {},
    data,
    axios
  );

export const updateProgram = (axios, data) =>
  update(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path
    },
    data,
    axios
  );

export const deleteProgram = (axios, data) =>
  remove(
    {
      path: ENTITY_NAME_IN_URI.PROGRAMS.path
    },
    data,
    axios
  );
